import type {
  ActionFunctionArgs,
  LoaderFunctionArgs,
} from "@remix-run/cloudflare";
import { Form, useLoaderData } from "@remix-run/react";
import { complete, insert } from "~/model/todo";
import { bail } from "~/util";

export const loader = async ({ context }: LoaderFunctionArgs) => {
  const { env } = context.cloudflare;
  const statement = env.db.prepare(`select * from todo`);
  const all = await statement.all<{
    msg: string;
    id: number;
    completed_on: Date | null;
  }>();

  return { success: true, items: all.results };
};

export async function action({ request, context }: ActionFunctionArgs) {
  const { env, ctx } = context.cloudflare;
  const form = await request.formData();

  if (request.method === "POST") {
    // Create the todo item
    const msg = form.get("msg")?.toString() ?? bail("message");
    await insert(env.db, msg);
  } else if (request.method === "PUT") {
    // Mark the todo item as completed
    const id = form.get("id")?.toString() ?? bail("no id");
    const timeToComplete = await complete(env.db, id);

    // Most frameworks actually catch errors in handlers, so we need an
    // uncaught error. The easiest way to do this just `waitUntil`
    ctx.waitUntil(logTimeToComplete(timeToComplete));
  } else {
    return { success: false };
  }

  return { success: true };
}

// Pretend we have this actually implemented, but whatever we're logging to is 
// having an outage so we throw an error.
async function logTimeToComplete(delta: number) {
  throw new Error(`Failed to log time to complete: ${delta}`);
}

export default function Index() {
  const data = useLoaderData<typeof loader>();
  const items = data.items.map((item) => (
    <li key={item.msg} className="flex flex-row gap-4 text-lg">
      <h2 className={item.completed_on ? "line-through" : ""}>{item.msg}</h2>
      {item.completed_on === null && (
        <Form method="PUT">
          <input hidden name="id" defaultValue={item.id} />
          <button type="submit">✅</button>
        </Form>
      )}
    </li>
  ));

  return (
    <main className="flex flex-col gap-10 w-screen h-screen items-center justify-center">
      <h1 className="text-5xl">Remix Todo</h1>
      <section className="flex flex-col rounded-lg p-6 gap-4">
        {data.items.length > 0 && <ul className="flex flex-col gap-2">{items}</ul>}
        <Form method="POST">
          <input
            className="outline-blue-400 rounded-md p-2 border border-blue-500"
            placeholder="Task"
            name="msg"
            type="text"
          />
        </Form>
      </section>
    </main>
  );
}
